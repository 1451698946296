import React from 'react';
import { Form, Grid, Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { useSignup } from '../SignupContext.react';
import DidYouKnow from './DidYouKnow';
import EmailInput from './EmailInput';
import PhoneNumberInput from './PhoneNumberInput';
import YourBookingSoFar from '../YourBookingSoFar';

const ReserveYourPlaceFields = () => {
  const {
    grownUp: {
      accountExists,
      emailValid,
      phoneNumberValid,
    },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const requiredDataCollected = (
    emailValid && phoneNumberValid
  );

  return (
    <Grid stackable columns={3}>
      <Grid.Column mobile={16} tablet={16} computer={6}>
        <YourBookingSoFar />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={16} computer={10}>
        <p>
          Fill out the details below to reserve your place for the next
          {' '}
          20 minutes whilst you finish signing up.
        </p>
        <Form.Group widths="equal">
          <EmailInput />
          <PhoneNumberInput />
        </Form.Group>
        <Form.Button
          labelPosition="right"
          fluid={isMobile}
          icon
          disabled={!requiredDataCollected || accountExists}
          primary
        >
          Continue enrolment
          <Icon name="right arrow" />
        </Form.Button>
        <DidYouKnow />
      </Grid.Column>
    </Grid>
  );
};

export default ReserveYourPlaceFields;
