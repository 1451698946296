import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Form, Icon, Segment } from 'semantic-ui-react';
import MarketingRadioButtons from './MarketingRadioButtons';
import RecordingConsentRadioButtons from './RecordingConsentRadioButtons';
import paths from '../../consts/paths';
import { useSignup } from './SignupContext.react';
import API from '../../store/api';


const PolicyAndTermsConsentFields = ({
  tsAndCsAgreed, onTsAndCsChange,
}) => {
  const {
    kid: { kidsAppOptIn, updateKid },
  } = useSignup();

  const [kidsAppFeatureEnabled, setKidsAppFeatureEnabled] = useState(false);

  useEffect(() => {
    API.getFeatureFlags().then((response) => {
      const { data } = response;
      setKidsAppFeatureEnabled(data.kids_app_opt_in);
    }).catch(() => {
      setKidsAppFeatureEnabled(false);
    });
  }, []);

  const handleChange = useCallback((_e, data) => {
    updateKid({ kidsAppOptIn: data.checked });
  }, []);

  const policyMessage = () => (
    <Segment secondary>
      <div className="flex-row align-center">
        <Icon name="check" size="big" style={{ marginRight: 8 }} />
        <div>
          Looking after you and your family&rsquo;s personal data is a priority for us.
          <br />
          Please see our{' '}
          <a href={paths.WWW_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            Privacy Notice
          </a>{' '}
          for more information.
        </div>
      </div>
    </Segment>
  );

  const tsAndCsCheckboxLabel = () => (
    <React.Fragment>
      I agree to the Rocksteady
      {' '}
      <span>
        <a href={paths.WWW_TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
      </span>
    </React.Fragment>
  );

  const kidsAppLabel = () => (
    <React.Fragment>
      I would like access to the Kids App for my child.
    </React.Fragment>
  );

  const kidsAppCheckbox = () => (
    <div className="field">
      <label>Rocksteady Kids App</label>
      <Form.Checkbox
        label={{ children: kidsAppLabel() }}
        checked={kidsAppOptIn}
        onChange={handleChange}
      />
    </div>
  );

  return (
    <React.Fragment>
      <MarketingRadioButtons />
      <RecordingConsentRadioButtons />
      {kidsAppFeatureEnabled && kidsAppCheckbox()}
      <Divider />
      <Form.Checkbox
        label={{ children: tsAndCsCheckboxLabel() }}
        checked={tsAndCsAgreed}
        onChange={onTsAndCsChange}
      />
      {policyMessage()}
    </React.Fragment>
  );
};

export default PolicyAndTermsConsentFields;

PolicyAndTermsConsentFields.propTypes = {
  tsAndCsAgreed: PropTypes.bool.isRequired,
  onTsAndCsChange: PropTypes.func.isRequired,
};
