import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { nameCase } from '@foundernest/namecase';
import { PropTypes } from 'prop-types';
import API from '../../store/api';
import PublicAPI from '../../store/PublicAPI';
import OFFERED_INSTRUMENTS from '../../consts/instruments';
import StringUtils from '../Utils/StringUtils';
import { MAX_FULL_NAME_LENGTH } from './StepFour/BankDetailsFields';

export const NO_INSTRUMENT_PREFERENCE_VALUE = 'no_preference';
const SUCCESS_ACTIONS = Object.freeze({ joinedWaitlist: 'joinedWaitlist', enrolled: 'enrolled' });

const SignupContext = createContext();
export const useSignup = () => useContext(SignupContext);

const initialPageState = {
  httpRequestPending: false,
  httpErrors: [],
  successAction: null,
};

const initialKidState = {
  kidId: null,
  kidName: null,
  kidSchoolYear: null,
  asOfSep: null,
  kidClassName: null,
  medicalAndEducationNotes: null,
  enrolmentInstrument: null,
  waitlistInstruments: [],
  firstPreferenceInstrument: NO_INSTRUMENT_PREFERENCE_VALUE,
  secondPreferenceInstrument: NO_INSTRUMENT_PREFERENCE_VALUE,
  concertVideoPermission: null,
  kidsAppOptIn: false,
};

const initialTimetableState = {
  timetableId: null,
  timetableName: null,
  lessonsAreOn: null,
  regularMonthlyCost: null,
  expectedNextTTPrice: null,
  availablePlacesCounts: {},
};

const initialGrownUpState = {
  accountExists: false,
  grownUpId: null,
  grownUpName: null,
  email: null,
  phoneNumber: null,
  addressL1: null,
  addressL2: null,
  addressCity: null,
  addressCounty: null,
  addressPostcode: null,
  firstPaymentDate: null,
  firstPaymentAmount: null,
  firstPaymentCoversPeriod: null,
  commsMarketing: null,
  alternativeContact: null,
  bankAddress: null,
  bankAccountFullName: null,
  bankAccountFirstName: null,
  bankAccountLastName: null,
  bankAccountNumber: null,
  bankSortCode: null,
  bacsReference: null,
  paymentReceiptEmail: null,
};

export default function SignupProvider({ children, loggedInStaff, schoolYearStartedIn }) {
  // -------------------------------------- PAGE ------------------------------------------

  const [
    {
      httpRequestPending,
      httpErrors,
      successAction,
    },
    setPageState,
  ] = useState(initialPageState);

  const actionSuccessful = !!successAction;
  const httpErrorsExist = !!httpErrors.length;

  const handleHttpRequestPending = () => {
    setPageState(prev => (
      { ...prev, httpRequestPending: true, httpErrors: initialPageState.httpErrors }
    ));
  };

  const handleHttpRequestFulfilled = () => {
    setPageState(prev => ({ ...prev, httpRequestPending: false }));
  };

  const pushToErrors = (errors) => {
    setPageState(prev => ({ ...prev, httpErrors: [...prev.httpErrors, ...Array(errors).flat()] }));
  };

  const handleHttpErrorReceipt = (error) => {
    pushToErrors(error || ['Unknown error']);
  };

  const resetHttpErrors = useCallback(() => {
    setPageState(prev => ({ ...prev, httpErrors: initialPageState.httpErrors }));
  }, []);

  // -------------------------------------------------------------------------------------
  // -------------------------------------- KID ------------------------------------------

  const [
    {
      kidId,
      kidName,
      kidSchoolYear,
      kidClassName,
      medicalAndEducationNotes,
      firstLessonDate,
      enrolmentInstrument,
      waitlistInstruments,
      firstPreferenceInstrument,
      secondPreferenceInstrument,
      concertVideoPermission,
      kidsAppOptIn,
    },
    setKidState,
  ] = useState({ ...initialKidState, asOfSep: schoolYearStartedIn });

  const capitalizedFullName = useMemo(() => (
    kidName && nameCase(kidName)
  ), [kidName]);

  const capitalizedFirstName = useMemo(() => (
    capitalizedFullName && capitalizedFullName.split(' ')[0]
  ), [kidName]);

  const kidNameValid = useMemo(() => (
    !!kidName && kidName.split(' ').filter(nameEl => !!nameEl).length > 1
  ), [kidName]);

  const trimAndCleanKidName = useCallback(() => {
    setKidState(prev => (
      { ...prev, kidName: StringUtils.trimAndCleanFullName(prev.kidName) }
    ));
  }, []);

  const onWaitlistFor = useCallback(instrumentName => (
    waitlistInstruments.includes(instrumentName)
  ), [waitlistInstruments]);
  const enrollingFor = useCallback(instrumentName => (
    enrolmentInstrument === instrumentName
  ), [enrolmentInstrument]);

  const offerFirstPreference = waitlistInstruments.length > 1;
  const enrollingAndJoiningWaitlist = !!enrolmentInstrument && !!waitlistInstruments.length;
  const offerSecondPreference = waitlistInstruments.length > 2;
  const firstPreferenceSet = (
    firstPreferenceInstrument !== initialKidState.firstPreferenceInstrument
  );
  const secondPreferenceSet = (
    secondPreferenceInstrument !== initialKidState.secondPreferenceInstrument
  );
  const justJoiningWaitlist = !enrolmentInstrument && !!waitlistInstruments.length;

  const updateKid = useCallback((changes) => {
    setKidState(prev => ({ ...prev, ...changes }));
  }, []);

  const resetKidInstrumentChoices = useCallback(() => {
    updateKid({
      enrolmentInstrument: initialKidState.enrolmentInstrument,
      waitlistInstruments: initialKidState.waitlistInstruments,
      firstPreferenceInstrument: initialKidState.firstPreferenceInstrument,
      secondPreferenceInstrument: initialKidState.secondPreferenceInstrument,
    });
  }, []);

  // -------------------------------------------------------------------------------------
  // ------------------------------------ GROWNUP ----------------------------------------

  const [
    {
      accountExists,
      grownUpId,
      grownUpName,
      email,
      phoneNumber,
      addressL1,
      addressL2,
      addressCity,
      addressCounty,
      addressPostcode,
      firstPaymentDate,
      firstPaymentAmount,
      firstPaymentCoversPeriod,
      commsMarketing,
      alternativeContact,
      bankAddress,
      bankAccountFullName,
      bankAccountFirstName,
      bankAccountLastName,
      bankAccountNumber,
      bankSortCode,
      bacsReference,
      paymentReceiptEmail,
    },
    setGrownUpState,
  ] = useState(initialGrownUpState);

  const updateGrownUp = useCallback((changes) => {
    setGrownUpState(prev => ({ ...prev, ...changes }));
  }, []);

  const splitGrownUpName = useMemo(() => _.words(grownUpName, /\S{2,}/g), [grownUpName]);

  const grownUpNameValid = useMemo(() => splitGrownUpName.length > 1, [splitGrownUpName]);

  const splitBankAccountFullName = useMemo(() => _.words(bankAccountFullName, /\S{2,}/g), [bankAccountFullName]);

  const bankAccountFullNameValid = useMemo(() => (
    splitBankAccountFullName.length > 1
  ), [splitBankAccountFullName]);

  const trimAndCleanGrownUpName = useCallback(() => {
    setGrownUpState(prev => (
      { ...prev, grownUpName: StringUtils.trimAndCleanFullName(prev.grownUpName) }
    ));
  }, []);

  const trimAndCleanEmail = useCallback(() => {
    setGrownUpState(prev => ({ ...prev, email: StringUtils.removeAllWhitespace(prev.email) }));
  }, []);

  const emailValid = useMemo(() => StringUtils.isEmailLike(email), [email]);

  const paymentReceiptEmailValid = useMemo(() => (
    StringUtils.isEmailLike(paymentReceiptEmail)
  ), [paymentReceiptEmail]);

  const trimAndCleanPaymentReceiptEmail = useCallback(() => {
    setGrownUpState(prev => (
      { ...prev, paymentReceiptEmail: StringUtils.removeAllWhitespace(prev.paymentReceiptEmail) }
    ));
  }, []);

  const phoneNumberValid = !!phoneNumber && phoneNumber.length >= 11;

  const addressValid = !!addressL1 && !!addressCity && !!addressPostcode;

  const cleanBankAccountNumber = useCallback(() => {
    setGrownUpState(prev => (
      { ...prev, bankAccountNumber: StringUtils.keepDigitsOnly(prev.bankAccountNumber) }
    ));
  }, []);

  const bankAccountNumberValid = useMemo(() => (
    StringUtils.isBankAccountNumberLike(bankAccountNumber)
  ), [bankAccountNumber]);

  const cleanSortCode = useCallback(() => {
    setGrownUpState(prev => (
      { ...prev, bankSortCode: StringUtils.keepDigitsOnly(prev.bankSortCode) }
    ));
  }, []);

  const bankSortCodeValid = useMemo(() => (
    StringUtils.isBankSortCodeLike(bankSortCode)
  ), [bankSortCode]);

  const checkIfAccountExists = () => {
    handleHttpRequestPending();
    PublicAPI.checkIfGrownUpExists({ email })
      .then(({ data: { grown_up_exists } }) => {
        updateGrownUp({ accountExists: grown_up_exists });
      })
      .finally(handleHttpRequestFulfilled);
  };

  // -------------------------------------------------------------------------------------
  // ----------------------------------- TIMETABLE ---------------------------------------

  const [
    {
      timetableId,
      timetableName,
      lessonsAreOn,
      regularMonthlyCost,
      expectedNextTTPrice,
      minSchoolYear,
      maxSchoolYear,
      availablePlacesCounts,
    },
    setTimetableState,
  ] = useState(initialTimetableState);

  const availablePlacesKnown = useMemo(() => (
    !_.isEmpty(availablePlacesCounts)
  ), [availablePlacesCounts]);

  const availablePlacesCountsFor = useCallback(instrumentName => (
    availablePlacesCounts[instrumentName]
  ), [availablePlacesCounts]);
  const instrumentsWithAvailablePlaces = useMemo(() => (
    OFFERED_INSTRUMENTS.filter(instrument => !!availablePlacesCountsFor(instrument))
  ), [availablePlacesCounts]);
  const placesAvailable = !!instrumentsWithAvailablePlaces.length;

  const updateTimetable = useCallback((changes) => {
    setTimetableState(prev => ({ ...prev, ...changes }));
  }, []);

  const resetTimetable = useCallback(() => {
    setTimetableState(initialTimetableState);
  }, []);

  const getTimetablePricingInfo = (id) => {
    handleHttpRequestPending();
    PublicAPI.getTimetablePricingInfo(id, { kids_app_opt_in: kidsAppOptIn })
      .then(({ data }) => {
        updateTimetable({
          lessonsAreOn: data.lessons_are_on,
          regularMonthlyCost: data.regular_monthly_cost,
          expectedNextTTPrice: data.expected_next_tt_price,
          minSchoolYear: data.min_year,
          maxSchoolYear: data.max_year,
          upcomingConcert: data.upcoming_concert,
        });
        updateKid({ firstLessonDate: data.first_lesson_date });
        updateGrownUp({
          firstPaymentDate: data.first_payment_date,
          firstPaymentAmount: data.first_payment_amount,
          firstPaymentCoversPeriod: data.first_payment_covers_period,
        });
      })
      .catch(handleHttpErrorReceipt)
      .finally(handleHttpRequestFulfilled);
  };

  const getTimetablePlaces = (id, year) => {
    handleHttpRequestPending();
    PublicAPI.getAvailablePlaces(id, year)
      .then(({ data }) => {
        updateTimetable({
          availablePlacesCounts: {
            drums: data.drums,
            vocals: data.vocals,
            guitar: data.guitar,
            keyboard: data.keyboard,
          },
        });
      })
      .catch(handleHttpErrorReceipt)
      .finally(handleHttpRequestFulfilled);
  };

  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------

  const configureReservePlaceParams = () => ({
    timetable_id: timetableId,
    year: kidSchoolYear,
    as_of_sep: schoolYearStartedIn,
    name: capitalizedFullName,
    would_do_drums: (enrollingFor('drums') || onWaitlistFor('drums')).toString(),
    would_do_vocals: (enrollingFor('vocals') || onWaitlistFor('vocals')).toString(),
    would_do_keyboard: (enrollingFor('keyboard') || onWaitlistFor('keyboard')).toString(),
    would_do_guitar: (enrollingFor('guitar') || onWaitlistFor('guitar')).toString(),
    instrument_first_choice: firstPreferenceInstrument,
    instrument_second_choice: secondPreferenceInstrument,
    instrument: enrolmentInstrument,
    parent_phone: phoneNumber,
    parent_email: email.toLowerCase(),
  });

  const configureAdditionalInfoParams = () => ({
    // NOTE: the kid's name here is actually used to just locate
    // the kid resource on backend, and the email to locate the grownup.
    name: capitalizedFullName,
    parent_email: email.toLowerCase(),
    parent_name: grownUpName,
    parent_alternative_contact: alternativeContact,
    parent_address_l1: addressL1,
    parent_address_l2: addressL2,
    parent_address_city: addressCity,
    parent_address_county: addressCounty,
    parent_address_postcode: addressPostcode,
    school_class: kidClassName,
    medical_and_education_notes: medicalAndEducationNotes,
    comms_marketing: commsMarketing,
    concert_video_permission: concertVideoPermission.toString(),
    kids_app_opt_in: kidsAppOptIn,
  });

  const configureBankAccountParams = () => ({
    bank_details: {
      grown_up_id: grownUpId,
      account_name: StringUtils.sanitizeNameForSD(bankAccountFullName),
      email_address: paymentReceiptEmail.toLowerCase(),
      account_number: bankAccountNumber,
      sort_code: bankSortCode,
      first_name: bankAccountFirstName,
      last_name: bankAccountLastName,
    },
  });

  const configureWaitlistJoinParams = () => ({
    ...configureReservePlaceParams(),
    ...configureAdditionalInfoParams(),
  });

  const configureCompleteEnrolmentParams = () => ({
    ...configureWaitlistJoinParams(),
    dd_email_address: paymentReceiptEmail.toLowerCase(),
    bank_account_name: StringUtils.sanitizeNameForSD(bankAccountFullName),
    bank_account_number: bankAccountNumber,
    bank_sortcode: bankSortCode,
  });

  const handleWaitlistJoin = () => {
    handleHttpRequestPending();
    PublicAPI.joinWaitingList(configureWaitlistJoinParams())
      .then(({ data }) => {
        updateKid({ kidId: data.kid_id });
        updateGrownUp({ grownUpId: data.grown_up_id });
        setPageState(prev => ({ ...prev, successAction: SUCCESS_ACTIONS.joinedWaitlist }));
      })
      .catch(handleHttpErrorReceipt)
      .finally(handleHttpRequestFulfilled);
  };

  const handleReservePlace = () => {
    handleHttpRequestPending();
    return new Promise((onResolve) => {
      PublicAPI.reserveAPlace(configureReservePlaceParams())
        .then(({ data }) => {
          updateKid({ kidId: data.kid_id });
          onResolve();
        })
        .catch(handleHttpErrorReceipt)
        .finally(handleHttpRequestFulfilled);
    });
  };

  const handleCompletePersonalInformation = () => {
    handleHttpRequestPending();
    return new Promise((onResolve) => {
      PublicAPI.completePersonalInformation(configureAdditionalInfoParams())
        .then(({ data }) => {
          updateGrownUp({ grownUpId: data.grown_up_id });
          onResolve();
        })
        .catch(handleHttpErrorReceipt)
        .finally(handleHttpRequestFulfilled);
    });
  };

  const handleBankDetailsValidation = () => {
    handleHttpRequestPending();
    return new Promise((onResolve) => {
      API.validateBankDetails(configureBankAccountParams())
        .then(({ data }) => {
          if (data.successful) {
            const bankInfo = data.successful.success[2];
            const {
              bank_name, branch, address1, town, county, postcode,
            } = bankInfo;
            updateGrownUp({
              bankAddress: `${bank_name}, ${branch}, ${address1}, ${town}, ${county}, ${postcode}`,
              bacsReference: data.bacs_reference,
            });
            onResolve();
          } else if (data.errors) {
            handleHttpErrorReceipt(data.errors.error);
          }
        })
        .catch(handleHttpErrorReceipt)
        .finally(handleHttpRequestFulfilled);
    });
  };

  const handleCompleteEnrolment = () => {
    handleHttpRequestPending();
    PublicAPI.completeApplication(configureCompleteEnrolmentParams())
      .then(() => {
        setPageState(prev => ({ ...prev, successAction: SUCCESS_ACTIONS.enrolled }));
      })
      .catch(handleHttpErrorReceipt)
      .finally(handleHttpRequestFulfilled);
  };

  useEffect(() => {
    if (timetableId) {
      getTimetablePricingInfo(timetableId);
    }
  }, [timetableId, kidsAppOptIn]);

  useEffect(() => {
    if (timetableId && kidSchoolYear !== null) {
      getTimetablePlaces(timetableId, kidSchoolYear);
    }

    if (enrolmentInstrument || waitlistInstruments.length) {
      resetKidInstrumentChoices();
    }
  }, [timetableId, kidSchoolYear]);

  // If a first preference is set but, due to other changes, should no longer offer an option
  // to set it, reset it.
  useEffect(() => {
    if (!offerFirstPreference && firstPreferenceSet && !enrollingAndJoiningWaitlist) {
      updateKid({
        firstPreferenceInstrument: initialKidState.firstPreferenceInstrument,
        secondPreferenceInstrument: initialKidState.secondPreferenceInstrument,
      });
    }
  }, [offerFirstPreference, firstPreferenceSet, enrollingAndJoiningWaitlist]);

  // If a second preference is set but, due to other changes, should no longer offer an option
  // to set it, reset it.
  useEffect(() => {
    if (!offerSecondPreference && secondPreferenceSet) {
      updateKid({ secondPreferenceInstrument: initialKidState.secondPreferenceInstrument });
    }
  }, [offerSecondPreference, secondPreferenceSet]);

  // If the first preference is not set but the second preference is set,
  // reset the second preference.
  useEffect(() => {
    if (!firstPreferenceSet && secondPreferenceSet) {
      updateKid({ secondPreferenceInstrument: initialKidState.secondPreferenceInstrument });
    }
  }, [firstPreferenceSet, secondPreferenceSet]);

  // If upon first prefrence change, and first and second are equal, reset the second preference.
  useEffect(() => {
    if (secondPreferenceSet && firstPreferenceInstrument === secondPreferenceInstrument) {
      updateKid({ secondPreferenceInstrument: initialKidState.secondPreferenceInstrument });
    }
  }, [firstPreferenceInstrument]);

  // If the waitlist instruments change and the first preference is no longer one
  // of the instruments, reset it
  useEffect(() => {
    if (firstPreferenceSet && !onWaitlistFor(firstPreferenceInstrument)) {
      updateKid({ firstPreferenceInstrument: initialKidState.firstPreferenceInstrument });
    }
  }, [waitlistInstruments]);

  // If the waitlist instruments change and the second preference is no longer one
  // of the instruments, reset it
  useEffect(() => {
    if (secondPreferenceSet && !onWaitlistFor(secondPreferenceInstrument)) {
      updateKid({ secondPreferenceInstrument: initialKidState.secondPreferenceInstrument });
    }
  }, [waitlistInstruments]);

  useEffect(() => {
    if (accountExists) {
      updateGrownUp({ accountExists: false });
    }
    updateGrownUp({ paymentReceiptEmail: email });
  }, [email]);

  // Initially presume the names associated with bank account will
  // match the name as specified earlier in application.
  // These can be changed individually on final step of application.
  useEffect(() => {
    updateGrownUp({
      bankAccountFullName: grownUpName?.substring(0, MAX_FULL_NAME_LENGTH - 1) || null,
      bankAccountFirstName: splitGrownUpName[0],
      bankAccountLastName: splitGrownUpName[splitGrownUpName.length - 1],
    });
  }, [grownUpName, splitGrownUpName]);

  // -------------------------------------------------------------------------------------

  const globalState = {
    loggedInStaff,
    schoolYearStartedIn,
    page: {
      httpRequestPending,
      httpErrors,
      httpErrorsExist,
      successAction,
      actionSuccessful,
      // ACTIONS
      resetHttpErrors,
    },
    kid: {
      kidId,
      kidName,
      capitalizedFirstName,
      kidNameValid,
      kidSchoolYear,
      kidClassName,
      medicalAndEducationNotes,
      firstLessonDate,
      enrolmentInstrument,
      waitlistInstruments,
      firstPreferenceInstrument,
      secondPreferenceInstrument,
      onWaitlistFor,
      offerFirstPreference,
      offerSecondPreference,
      firstPreferenceSet,
      secondPreferenceSet,
      justJoiningWaitlist,
      enrollingAndJoiningWaitlist,
      concertVideoPermission,
      kidsAppOptIn,
      // ACTIONS
      updateKid,
      trimAndCleanKidName,
      resetKidInstrumentChoices,
      handleWaitlistJoin,
      handleReservePlace,
      handleCompletePersonalInformation,
    },
    grownUp: {
      accountExists,
      grownUpId,
      grownUpName,
      grownUpNameValid,
      email,
      emailValid,
      phoneNumber,
      phoneNumberValid,
      addressL1,
      addressL2,
      addressCity,
      addressCounty,
      addressPostcode,
      addressValid,
      firstPaymentDate,
      firstPaymentAmount,
      firstPaymentCoversPeriod,
      commsMarketing,
      alternativeContact,
      bankAddress,
      bankAccountFullName,
      bankAccountFullNameValid,
      bankAccountFirstName,
      bankAccountLastName,
      bankAccountNumber,
      bankAccountNumberValid,
      bankSortCode,
      bankSortCodeValid,
      bacsReference,
      paymentReceiptEmail,
      paymentReceiptEmailValid,
      // ACTIONS
      updateGrownUp,
      trimAndCleanGrownUpName,
      trimAndCleanEmail,
      trimAndCleanPaymentReceiptEmail,
      cleanBankAccountNumber,
      cleanSortCode,
      checkIfAccountExists,
      handleBankDetailsValidation,
      handleCompleteEnrolment,
    },
    timetable: {
      timetableId,
      timetableName,
      lessonsAreOn,
      regularMonthlyCost,
      expectedNextTTPrice,
      minSchoolYear,
      maxSchoolYear,
      availablePlacesCounts,
      availablePlacesKnown,
      placesAvailable,
      availablePlacesCountsFor,
      instrumentsWithAvailablePlaces,
      // ACTIONS
      updateTimetable,
      resetTimetable,
    },
  };

  return (
    <SignupContext.Provider value={globalState}>
      {children}
    </SignupContext.Provider>
  );
}

SignupProvider.propTypes = {
  children: PropTypes.node.isRequired,
  loggedInStaff: PropTypes.bool.isRequired,
  schoolYearStartedIn: PropTypes.number.isRequired,
};
