import React from 'react';
import { Form } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import { useSignup } from '../SignupContext.react';
import JoinWaitlistFields from './JoinWaitlistFields';
import ReserveYourPlaceFields from './ReserveYourPlaceFields';

const StepTwo = ({ onStepComplete }) => {
  const {
    kid: { justJoiningWaitlist, handleWaitlistJoin, handleReservePlace },
    page: { httpRequestPending },
  } = useSignup();

  const formTitle = justJoiningWaitlist ? 'join waitlist' : 'reserve your place';

  const submitAction = () => {
    if (justJoiningWaitlist) {
      handleWaitlistJoin();
    } else {
      handleReservePlace().then(onStepComplete);
    }
  };

  return (
    <Form loading={httpRequestPending} onSubmit={submitAction}>
      <h2>{formTitle}</h2>
      {justJoiningWaitlist ? <JoinWaitlistFields /> : <ReserveYourPlaceFields />}
    </Form>
  );
};

export default StepTwo;

StepTwo.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
};
