import React, { useCallback, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { PropTypes } from 'prop-types';
import { useSignup } from '../SignupContext.react';
import EnrolFields from './EnrolFields';

const StepThree = ({ onStepComplete }) => {
  const {
    kid: { handleWaitlistJoin, handleCompletePersonalInformation },
    page: { httpRequestPending },
  } = useSignup();

  const [bankAccountInOwnName, setBandAccountInOwnName] = useState(null);
  const [onlyBankSignatoryRequired, setOnlyBankSignatoryRequired] = useState(null);

  const handleAccountInOwnNameChange = useCallback((_e, { value }) => {
    setBandAccountInOwnName(value === 'yes');
  }, []);

  const handleOnlyBankSignatoryRequiredChange = useCallback((_e, { value }) => {
    setOnlyBankSignatoryRequired(value === 'yes');
  }, []);

  const canEnrol = !!bankAccountInOwnName && !!onlyBankSignatoryRequired;

  const submitAction = () => {
    if (canEnrol) {
      handleCompletePersonalInformation().then(onStepComplete);
    } else {
      handleWaitlistJoin();
    }
  };

  return (
    <Form loading={httpRequestPending} onSubmit={submitAction}>
      <h2>Almost done...</h2>
      <EnrolFields
        canEnrol={canEnrol}
        bankAccountInOwnName={bankAccountInOwnName}
        onlyBankSignatoryRequired={onlyBankSignatoryRequired}
        onBankAccountInOwnNameChange={handleAccountInOwnNameChange}
        onOnlyBankSignatoryRequiredChange={handleOnlyBankSignatoryRequiredChange}
      />
    </Form>
  );
};

export default StepThree;

StepThree.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
};

