import React, { useCallback } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';
import NameInput from '../NameInput';
import SchoolYearSelect from './SchoolYearSelect';
import TimetableSelect from './TimetableSelect';

const CheckLessonTimes = () => {
  const {
    kid: {
      kidName, updateKid, kidNameValid, trimAndCleanKidName,
    },
  } = useSignup();

  const handleNameChange = useCallback(value => updateKid({ kidName: value }), []);

  return (
    <div>
      <h3>1. check lesson times</h3>
      <Form warning>
        <Grid stackable columns={2}>
          <Grid.Column mobile={16}>
            <TimetableSelect />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={16}>
            <NameInput
              value={kidName || ''}
              attrName="kidName"
              onChange={handleNameChange}
              isValid={kidNameValid}
              label="Your child's full name"
              onBlur={trimAndCleanKidName}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={16}>
            <SchoolYearSelect />
          </Grid.Column>
        </Grid>
      </Form>
    </div>
  );
};

export default CheckLessonTimes;
