import React from 'react';
import { Form } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';
import BankDetailsFields from './BankDetailsFields';

const StepFour = () => {
  const {
    grownUp: { handleBankDetailsValidation, handleCompleteEnrolment },
    page: { httpRequestPending },
  } = useSignup();

  const handleSubmit = () => {
    handleBankDetailsValidation().then(handleCompleteEnrolment);
  };

  return (
    <Form loading={httpRequestPending} onSubmit={handleSubmit}>
      <h2>set up direct debit</h2>
      <BankDetailsFields />
    </Form>
  );
};

export default StepFour;
