import React from 'react';
import { Grid } from 'semantic-ui-react';

const DidYouKnow = () => (
  <div className="background-grey p-16">
    <h2>did you know?</h2>
    <Grid stackable columns={3}>
      <Grid.Column>
        <h4>Hassle free</h4>
        <p>
          Lessons are hassle free, they happen within school time, no experience necessary and
          we provide all the instruments.
        </p>
      </Grid.Column>
      <Grid.Column>
        <h4>Risk free</h4>
        <p>
          Rocksteady is a risk free subscription service with no minimum commitment - if you
          cancel within the first month you won&rsquo;t be charged anything, you can also cancel
          at any point after this, just let us know by the 20th of any month and your payments
          will be stopped.
        </p>
      </Grid.Column>
      <Grid.Column>
        <h4>Grow in confidence</h4>
        <p>
          As well as learning how to play an instrument in a band, through the Rocksteady
          program children grow in confidence, make new friends and develop important life
          skills.
        </p>
      </Grid.Column>
    </Grid>
  </div>
);

export default DidYouKnow;
