import React from 'react';
import paths from '../../../consts/paths';

const BackstageLinkSection = () => (
  <div className="mb-24">
    <p>
      If you have any questions, please call us on{' '}
      <a href="tel:+443301130330`"><strong>0330 113 0330.</strong></a>
    </p>
    <h4>
      Login to <em>Backstage</em>
    </h4>
    <p>
      If you would like to enrol another child, or update your preferences at any time you can
      now{' '}
      <a href={paths.BACKSTAGE}>
        setup and login to your <em>Backstage</em> account
      </a>
      .
    </p>
    <p>
      <a href={paths.BACKSTAGE} className="ui button primary large">
        Setup your Backstage account <i className="ui icon arrow right" />
      </a>
    </p>
  </div>
);

export default BackstageLinkSection;
