import React, { useMemo } from 'react';
import { Button, Modal } from 'semantic-ui-react';

const FAQModal = () => {
  // Customer support opening hours
  const hours = useMemo(() => {
    const supportHoursElement = document.getElementById('customer_support_opening_hours');
    if (supportHoursElement) {
      return supportHoursElement.getAttribute('data');
    }

    return null;
  }, []);

  return (
    <Modal
      closeIcon
      trigger={
        <Button size="small" positive>
          FAQs
        </Button>
      }
    >
      <Modal.Header>Did you know?</Modal.Header>
      <Modal.Content scrolling>
        <h3>Hassle free</h3>
        <p>
          Lessons are hassle free, they happen within school time,
          no experience necessary and we provide all the instruments.
        </p>

        <h3>Risk free</h3>
        <p>
          Rocksteady is a risk free subscription service with no minimum
          commitment - if you cancel within the first month you
          won&lsquo;t be charged anything, you can also cancel at any point
          after this, just let us know by the 20th of any month and your payments will be stopped.
        </p>

        <h3>Grow in confidence</h3>

        <p>
          As well as learning how to play an instrument in a band, through the Rocksteady program
          children grow in confidence, make new friends and develop important life skills.
        </p>

        <h3>Rock concert</h3>
        <p>
          At the end of each term your childs band will feature in their
          own rock concert in front of you and the rest of the school.
        </p>

        <h3>Stimulate both sides of the brain</h3>
        <p>
          Listening and playing music at the same time stimulates both sides of the brain which
          increases productivity for the rest of the day our research suggests that children on the
          Rocksteady program often improve performance in core subjects like maths and english.
        </p>

        <h3>What parents say</h3>
        <p>
          <em>
            &ldquo;We are so proud to see how far Coral has come,
            being such a shy little girl and now
            she can sing in front of an audience and do it so beautifully.&rdquo;
          </em>
          <br />&mdash; Rosa, Proud Parent
        </p>

        <h3>What schools say</h3>
        <p>
          <em>
            &ldquo;Concerts like the ones you ran this morning will
            be remembered for a very long time
            if not the rest of these children’s lives!&rdquo;
          </em>
          <br />&mdash; Lyndsey Hodkinson, Deputy Headteacher, Thames Ditton Junior School
        </p>

        <h3>Further questions</h3>
        <p>
          If you have any further questions or would just like more information you can call us on{' '}
          <a href="tel:+443301130330">0330 113 0330</a> ({ hours }) or email{' '}
          <a href="mailto:enrol@rocksteadymusicschool.com">enrol@rocksteadymusicschool.com</a>
        </p>

        <br />
        <br />
      </Modal.Content>
    </Modal>
  );
};

export default FAQModal;
