import React, { useCallback, useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import AltContactInput from '../AltContactInput';
import NameInput from '../NameInput';
import PolicyAndTermsConsentFields from '../PolicyAndTermsConsentFields';
import { useSignup } from '../SignupContext.react';
import AddressInput from '../AddressInput';
import EmailInput from './EmailInput';
import PhoneNumberInput from './PhoneNumberInput';
import SupplementaryKidInputs from '../SupplementaryKidInputs';
import YourBookingSoFar from '../YourBookingSoFar';

const JoinWaitlistFields = () => {
  const {
    kid: { concertVideoPermission },
    grownUp: {
      accountExists,
      grownUpName,
      grownUpNameValid,
      emailValid,
      phoneNumberValid,
      addressValid,
      updateGrownUp,
      commsMarketing,
      trimAndCleanGrownUpName,
    },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const [tsAndCsAgreed, setTsAndCsAgreed] = useState(false);

  const handleTsAndCsToggle = useCallback(() => {
    setTsAndCsAgreed(prev => !prev);
  }, []);

  const handleNameChange = useCallback((value) => {
    updateGrownUp({ grownUpName: value });
  }, [updateGrownUp]);

  const requiredDataCollected = (
    grownUpNameValid
      && emailValid
      && phoneNumberValid
      && addressValid
      && concertVideoPermission !== null
      && commsMarketing !== null
      && tsAndCsAgreed
  );

  return (
    <Grid stackable columns={3}>
      <Grid.Column mobile={16} tablet={16} computer={5}>
        <YourBookingSoFar />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={10} computer={5}>
        <NameInput
          value={grownUpName || ''}
          attrName="grownUpName"
          onChange={handleNameChange}
          isValid={grownUpNameValid}
          label="Your full name"
          onBlur={trimAndCleanGrownUpName}
        />
        <EmailInput />
        <PhoneNumberInput />
        <AltContactInput />
        <AddressInput />
        <SupplementaryKidInputs />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={6} computer={6}>
        <PolicyAndTermsConsentFields
          tsAndCsAgreed={tsAndCsAgreed}
          onTsAndCsChange={handleTsAndCsToggle}
        />
        <Form.Button
          floated="right"
          fluid={isMobile}
          disabled={!requiredDataCollected || accountExists}
          primary
          content="Join waitlist"
        />
      </Grid.Column>
    </Grid>
  );
};

export default JoinWaitlistFields;
